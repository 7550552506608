<script lang="ts" setup>
import LanguageSwitcher from '@/components/LanguageSwitcher.vue'
import PlatformSwitcher from '@/components/PlatformSwitcher.vue'

const route = useRoute()

const { platform } = storeToRefs(useSystemStore())

const { mdAndUp } = useDisplay()

/**
 * Key for the footer to force a re-render when the viewport width changes.
 *
 * This is needed because Vuetify will crash if we try to dynamically change the `app` prop.
 */
const key = computed(() => (mdAndUp.value ? 'desktop' : 'mobile'))

const isPlatformSwitcher = computed(() => {
  const redirectURL = sessionStorage.getItem('redirectURL') || ''
  const hasNextLocation = !!(redirectURL && redirectURL !== '/')
  return (
    ['CH', 'EU'].includes(platform.value) &&
    !hasNextLocation &&
    ['login', 'signup', 'business-signup'].includes(String(route.name))
  )
})
</script>

<template>
  <v-footer :key="key" :absolute="mdAndUp" :app="mdAndUp" color="white">
    <v-row justify="space-between" no-gutters>
      <v-col cols="12" md="auto" class="flex-grow-1">
        <v-row align="center" no-gutters>
          <v-col v-if="isPlatformSwitcher" order="1" order-md="2" cols="12" md="auto">
            <platform-switcher />
          </v-col>
          <v-col order="2" order-md="1" cols="12" md="auto">
            <language-switcher />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="auto">
        <nav class="sitefooter__menu">
          <ul class="sitefooter__list">
            <li class="sitefooter__list-item">
              <a
                class="sitefooter__link"
                target="_blank"
                rel="noopener"
                :href="$t('global.legal.privacy_policy_link')"
                >{{ $t('global.legal.privacy_policy') }}</a
              >
            </li>
            <li class="sitefooter__list-item">
              <a class="sitefooter__link" target="_blank" rel="noopener" :href="$t('global.legal.terms_of_use_link')">{{
                $t('global.legal.terms_of_use')
              }}</a>
            </li>
            <li class="sitefooter__list-item">
              <a class="sitefooter__link" target="_blank" rel="noopener" :href="$t('global.legal.gtc_link')">{{
                $t('global.legal.gtc')
              }}</a>
            </li>
            <li class="sitefooter__list-item">
              <a class="sitefooter__link" target="_blank" rel="noopener" :href="$t('global.legal.imprint_link')">{{
                $t('global.legal.imprint')
              }}</a>
            </li>
          </ul>
        </nav>
      </v-col>
    </v-row>
  </v-footer>
</template>

<style scoped lang="sass">

.sitefooter
  &__menu
    +max-width
      width: 100%

  &__list
    display: flex
    +clear-list-styles
    +min-width
      padding: 0 14px
    +max-width
      flex-direction: column

    &-item
      +max-width
        display: block
        border-bottom: 1px solid $c-border

  &__link
    display: inline-flex
    align-items: center
    height: 60px
    padding: 10px
    +apply-default-link-styling
    +apply-mobile-padding
    +max-width
      display: inline-flex
      justify-content: flex-start
      align-items: center
      height: 14 * $bw
</style>
