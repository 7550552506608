<script lang="ts" setup>
const languageStore = useLanguageStore()
const route = useRoute()
</script>

<template>
  <nav class="lang-switch" data-cy="language_switcher">
    <v-menu>
      <template #activator="{ props }">
        <v-btn variant="text" color="primary" v-bind="props">
          <template v-if="languageStore.locale === 'de-ch'">
            <nuxt-img class="lang-switch__flag" width="20" height="20" src="/img/flags/DE.svg" />
            Deutsch
          </template>
          <template v-else-if="languageStore.locale === 'en-gb'">
            <nuxt-img class="lang-switch__flag" width="20" height="20" src="/img/flags/GB.svg" />
            English
          </template>
          <template v-else-if="languageStore.locale === 'fr-ch'">
            <nuxt-img class="lang-switch__flag" width="20" height="20" src="/img/flags/FR.svg" />
            Français
          </template>
          <v-icon>custom:expand_more</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="locale in languageStore.selectableLocales"
          :key="locale"
          :class="['lang-switch__link', { 'is-active': languageStore.locale === locale }]"
          :to="{ path: route.fullPath, query: { lang: locale.split('-')[0] } }"
          :ripple="false"
          exact
        >
          <template v-if="locale === 'de-ch'">
            <nuxt-img
              class="lang-switch__flag"
              width="20"
              height="20"
              src="/img/flags/DE.svg"
              data-cy="german_selector"
            />
            Deutsch
          </template>
          <template v-else-if="locale === 'en-gb'">
            <nuxt-img
              class="lang-switch__flag"
              width="20"
              height="20"
              src="/img/flags/GB.svg"
              data-cy="english_selector"
            />
            English
          </template>
          <template v-else-if="locale === 'fr-ch'">
            <nuxt-img
              class="lang-switch__flag"
              width="20"
              height="20"
              src="/img/flags/FR.svg"
              data-cy="french_selector"
            />
            Français
          </template>
        </v-list-item>
      </v-list>
    </v-menu>
  </nav>
</template>

<style scoped lang="sass">
.lang-switch
  display: flex
  justify-content: space-between
  align-items: center
  padding-top: $bw
  padding-bottom: $bw
  +min-width
    height: $sitefooter-height
  +max-width
    justify-content: flex-start
    width: 100%
    height: 56px
    padding: 0
    border-top: 1px solid $c-border
    border-bottom: 1px solid $c-border

  .v-icon
    margin-left: 4px
    margin-top: 2px

  .v-btn
    font-size: 1rem
    &.text-primary
      color: $c-text !important
    &:before
      background: $c-primary

  &__list
    display: flex
    +clear-list-styles
    +min-width
      margin-left: -10px
    +max-width
      flex-direction: column
      width: 100%

  &__link
    +apply-default-link-styling
    +max-width

    :deep(.v-list-item__content)
      display: flex
      align-items: center
    &.is-active
      font-weight: bold
      color: $c-text-alt
      cursor: default
      &::before
        opacity: 0 // Counteract Vuetify default styling

  &__flag
    margin-right: 4 * $bw
</style>
